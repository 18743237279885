import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { ScreenReaderBasic } from "../../examples/utilities/ScreenReader";
import CodeHighlight from "../../common/CodeHighlight";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  Hide an element to all devices <strong>except screen readers</strong> with{" "}
  <code>.sr-only</code>. Combine <code>.sr-only</code> with{" "}
  <code>.sr-only-focusable</code> to show the element again when it’s focused
  (e.g. by a keyboard-only user). Can also be used as mixins.
    </p>
    <CodeHighlight code={`<a class="sr-only sr-only-focusable" href="#content">Skip to main content</a>`} className="highlight" mdxType="CodeHighlight"></CodeHighlight>
    <br />
    <CodeHighlight code={ScreenReaderBasic} language="scss" className="highlight" mdxType="CodeHighlight"></CodeHighlight>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      